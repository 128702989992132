import React from "react";
import "./FloatingBalls.css";
const FloatingBalls = () => {
  return (
    <div>
      <div className="ball ball1"></div>
      <div className="ball ball2"></div>
      <div className="ball ball3"></div>
    </div>
  );
};

export default FloatingBalls;